<template>
    <div class="content">
        <div class="main">
            <el-card class="box-card">
                <el-input placeholder="请输入用户名" v-model="username" prefix-icon="el-icon-s-custom" clearable v-if="passwordLogin"></el-input>
                <el-input placeholder="请输入密码" v-model="password" prefix-icon="el-icon-lock" show-password v-if="passwordLogin"></el-input>
                <el-input placeholder="请输入手机号" v-model="phone" prefix-icon="el-icon-s-custom" v-if="!passwordLogin" clearable
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" maxlength="11"></el-input >
                <el-input placeholder="请输入验证码" v-model="code" prefix-icon="el-icon-view" v-if="!passwordLogin"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" maxlength="6">
                    <template slot="append"><el-button @click="sendSms" type="primary" :disabled="smsDisable">{{smsText}}</el-button></template>
                </el-input>
                <el-button @click=login id="btn-login" type="primary">登录</el-button>
                <el-divider></el-divider>
                <div class="login-type">
                    <el-link v-if="passwordLogin" @click="changeLoginType">短信登录</el-link>
                    <el-link v-if="!passwordLogin" @click="changeLoginType">密码登录</el-link>
                </div>
            </el-card>

        </div>
        <vue-particles
                color="#dedede"
                :particleOpacity="0.7"
                :particlesNumber="80"
                shapeType="circle"
                :particleSize="4"
                linesColor="#dedede"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="3"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
        >
        </vue-particles>
    </div>
</template>

<style>
    .login-type {
        text-align: right;
        padding-right: 20px;
    }
</style>

<script>
    export default {
        data() {
            return {
                phone: '',
                username: '',
                passwordLogin : true,
                password: '',
                code : '',
                smsText : "发送验证码",
                smsDisable : false
            }
        },
        methods: {
            login: function () {
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.2)'
                });

                this.$auth.removeToken();

                if (this.passwordLogin) {
                    this.pwdLogin(loading);
                } else {
                    this.smsLogin(loading);
                }
            },
            smsLogin(loading) {
                let that = this;
                let params = {
                    phone: this.phone,
                    smsCode: this.code
                };
                this.$api.LoginMod.smsLogin(params)
                    .then(function (response) {
                        that.handleLoginResult(loading, response);
                    })
                    .catch(function () {
                        loading.close();
                        that.$message.error('网络异常');
                    })
            },
            pwdLogin(loading) {
                let that = this;
                let params = {
                    username: this.username,
                    password: this.password
                };

                this.$api.LoginMod.pwdLogin(params)
                    .then(function (response) {
                        that.handleLoginResult(loading, response);
                    })
                    .catch(function () {
                        loading.close();
                        that.$message.error('网络异常');
                    })
            },
            handleLoginResult(loading, response) {
                let that = this;
                let ret = response.data;
                if (ret.code === 200) {
                    that.$auth.setToken(ret.data.token);
                    that.$auth.setRefreshToken(ret.data.refreshToken);
                    that.$store.dispatch("getUserInfo").then(() => {
                        sessionStorage.setItem("refreshed", "0");
                        that.$store.dispatch("clearAppState");
                        that.$router.push({path: '/home'});
                    });
                    loading.close();
                } else {
                    loading.close();
                    that.$message.error(ret.message || '登录失败');
                }
            },
            sendSms() {
                this.$message({
                    message: '短信发送成功',
                    type: 'success'
                });
                let seconds = 60;
                let that = this;
                that.smsDisable = true;
                let itvSms = setInterval(function () {
                    if (seconds <= 0) {
                        that.smsText = "发送验证码";
                        that.smsDisable = false;
                        clearInterval(itvSms);
                        return;
                    }
                    that.smsText = seconds + "s";
                    seconds--;
                }, 1000)
            },
            changeLoginType() {
                this.passwordLogin = !this.passwordLogin;
            }
        }
    }
</script>


<style>
    .content {
        height: 100%;
        overflow: hidden;
    }

    #particles-js {
        background-image: url("../assets/index.jpg");
        background-size:100% 100%;
    }

    .main {
        position: absolute;
        top: 320px;
        left: -160px;
        width: 320px;
        height: 140px;
        margin-left: 50%;
    }

    #btn-login {
        margin-top: 20px;
        outline: none;
        /*color: #aaa;*/
        /*background-color: #FFF;*/
        width: 100%;
        height: 40px;
        border-radius: 20px;
        border: 0px;
    }

    .el-input {
        margin-top: 15px;
    }

    #btn-login:hover {
        color: #4a79a3;
        cursor: pointer;
    }
</style>
